@media only screen and (max-width: 1192px) {
    .css-15yp1i4-MuiPaper-root-MuiDialog-paper {
        min-width: 100% !important;
    }
}

@media only screen and (max-width: 991px) {
    .css-ap8o75-MuiPaper-root-MuiDialog-paper {
        min-width: 100% !important;
    }
}

@media only screen and (max-width: 767px) {
    .css-1xi5xj9-MuiPaper-root-MuiDialog-paper {
        min-width: 100% !important;
    }
}
