/*
AutocompleteRenderer down arrow
*/

.handsontable .htAutocompleteArrow {
  float: inline-end;
  font-size: 10px;
  color: #EEE;
  cursor: default;
  width: 16px;
  text-align: center;
}

.handsontable td .htAutocompleteArrow:hover {
  color: #777;
}

.handsontable td.area .htAutocompleteArrow {
  color: #d3d3d3;
}
